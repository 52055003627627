import QRCodeStyling from 'qr-code-styling';
import { useEffect, useRef, useState } from 'react';
import { getWebsiteConfig } from '../../../whiteLabel/themeFactory';

interface QrCodeComponentProps {
  ticketQrCode: string;
  securityLevel: 'LOW' | 'MEDIUM' | 'HIGH';
}

const QrCodeComponent = (props: QrCodeComponentProps) => {
  const { ticketQrCode, securityLevel } = props;
  const containerRef = useRef<HTMLDivElement>();
  const [qr, setQR] = useState<QRCodeStyling>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string>();

  const id = getWebsiteConfig()?.id;
  // const nftIds = tickets?.map(ticket => ticket.nftId);
  // const ticketIds = tickets?.map(ticket => ticket.id);

  // const { signTicket } = useWeb3Auth();
  useEffect(() => {
    sign();
  }, []);

  function moveNumberToFirstPosition(list: number[], numberToMove: number): number[] {
    // Find the index of the number to move
    const index = list.indexOf(numberToMove);
    // If the number is already in the first position, we don't need to do anything
    if (index === 0) {
      return list;
    } else if (index > 0) {
      // If the number is in the list but not in the first position
      // Remove the number from its current position
      list.splice(index, 1);
      // Insert the number at the beginning of the list
      list.unshift(numberToMove);
      return list;
    } else {
      // If the number is not in the list
      throw new Error(`The number ${numberToMove} is not in the list.`);
    }
  }

  const generateQrCodeData = async () => {
    if (securityLevel === 'LOW') {
      return ticketQrCode;
    } else if (securityLevel === 'MEDIUM') {
      // const content: TicketSignatureContent = {
      //   nftIds: moveNumberToFirstPosition(nftIds, ticket.nftId),
      //   userAddress: user?.publicKey ?? '',
      //   ticketIds: moveNumberToFirstPosition(ticketIds, ticket.id)
      // };
      // const signature = await signTicket(content, event.web3Address, event.chain);
      // const data = JSON.stringify({
      //   content: JSON.stringify(content),
      //   signature
      // });
      // return data;
    } else if (securityLevel === 'HIGH') {
      // TODO: Implement security level 3 - rotating QR code
      return ticketQrCode;
    } else {
      return ticketQrCode;
    }
  };

  const sign = async () => {
    setErrorMessage(undefined);
    // if (!props.event.web3Address) return;
    try {
      const data = await generateQrCodeData();

      const qrCode = new QRCodeStyling({
        width: 240,
        height: 240,
        type: 'canvas',
        data: data,
        // TODO remove after sporsora
        // image:
        //   id == 'SPORSORA'
        //     ? 'https://tickie-prod.s3.eu-west-3.amazonaws.com/clients/sporsora/logo/logo-2.jpg'
        //     : '/images/logo/tickie.png',
        margin: 6,
        qrOptions: {
          typeNumber: 0
        },
        dotsOptions: {
          color: 'black',
          type: 'classy'
        },
        backgroundOptions: {
          color: '#ffffff',

          round: 0.1
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 4,
          imageSize: 0.33
        }
      });

      qrCode.append(containerRef.current);
      setQR(qrCode);
    } catch (error) {
      setErrorMessage(error as string);
      console.log(error);
      return;
    }
  };

  return (
    <div>
      <div ref={containerRef as any}></div>
    </div>
  );
};

export default QrCodeComponent;
