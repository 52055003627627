import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, pdf } from '@react-pdf/renderer';
import { Ticket } from '../../../../services/api/ticket';
import { format } from 'date-fns';
import { Event } from '../../../../services/api/event';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';

import { Print } from '@mui/icons-material';
import Icon from './Icon';

// import Icon from './Icon';
import calendar from './date.png';
import location from './location.png';
import email from './email.png';
import tickieLogo from './tickie.png';
const styles = StyleSheet.create({
  ticket: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid black' // Add position relative to the ticket container
  },
  sectionTwo: {
    flexDirection: 'row',
    backgroundColor: '#f2f2f3',
    justifyContent: 'space-between', // Center elements horizontally and add extra spacing between them
    width: '100%',
    height: '20%'
  },
  sectionThree: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Center elements horizontally and add extra spacing between them
    width: '100%',
    height: '90%'
  },
  headerContainer: {
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    width: '100%',
    height: '100%'
  },
  firstPartContainer: {
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    width: '100%',
    height: '50%'
  },
  SecondPartContainer: {
    flexDirection: 'column',
    alignItems: 'center', // Center horizontally
    width: '100%',
    height: '50%'
  },
  header: {
    fontSize: 50,
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold'
  },
  exactAddress: {
    fontSize: 20,
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold'
  },
  timeDateContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center', // Center horizontally
    marginTop: 20 // Add margin top
  },
  timeDate: {
    fontSize: 20,
    color: '#fff',
    marginRight: 10,
    fontFamily: 'Helvetica-Bold'
  },
  details: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 10
  },
  titleSubtitleColumn: {
    marginTop: 10,
    marginLeft: 20,
    flexDirection: 'column',
    width: '100%'
  },
  qrColumn: {
    marginTop: 10,
    marginLeft: 20,
    flexDirection: 'column',
    width: '100%'
  },
  qrBilletCol: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center', // Center elements vertically
    justifyContent: 'center' // Center horizontally
  },
  ticketDetails: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center', // Center elements vertically

    padding: 20
  },
  dateAndAddressColumn: {
    marginTop: 10,
    flexDirection: 'column',
    marginLeft: 20,
    width: '100%'
  },
  titleSubtitle: {
    fontSize: 10,
    marginBottom: 5,
    fontFamily: 'Helvetica-Bold'
  },
  place: {
    fontSize: 8,
    marginBottom: 5
  },
  eventName: {
    fontSize: 8,
    marginBottom: 5,
    fontFamily: 'Helvetica-Bold'
  },
  barcode: {
    marginTop: 10,
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%'
  },
  calendarIcon: {
    marginRight: 2,
    marginBottom: 6,
    width: 8,
    height: 8
  },
  emailIcon: {
    marginRight: 2,
    marginBottom: 6,
    marginLeft: 3,
    width: 8,
    height: 8
  },
  TickieIcon: {
    height: 8
  },
  lastSection: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center'
  }
});

interface PdfTicketProps {
  tickets: Ticket[];
  event?: Event;
}

const PdfTicket = ({ tickets, event }: PdfTicketProps) => {
  const ticketsWithUrl = tickets.map(ticket => ({
    ...ticket,
    qrCodeUrl: QRCode.toDataURL(ticket.qrCode)
  }));

  return (
    <Document>
      {ticketsWithUrl.map((ticket, index) => (
        <Page size="A4" style={styles.ticket}>
          <View>
            <View style={styles.headerContainer}>
              <View style={styles.firstPartContainer}>
                <Image
                  src={event?.cover?.replace('.webp', '.png')}
                  style={{ width: '100%', height: '80%', objectFit: 'cover' }}
                />
                <View style={styles.sectionTwo}>
                  <View style={styles.titleSubtitleColumn}>
                    <Text style={styles.titleSubtitle}>{event?.title.toUpperCase()}</Text>
                    {/* {event?.organization.name && <Text style={styles.place}>Par {event.organization.name}</Text>} */}
                  </View>
                  <View style={styles.dateAndAddressColumn}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Image src={calendar} style={styles.calendarIcon} />
                      <Text style={styles.eventName}>DATE DE L’ÉVÈNEMENT</Text>
                    </View>
                    <Text style={styles.place}>
                      {event?.beginAt && event?.endAt && formatRange(event?.beginAt, event?.endAt)}
                    </Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Image src={location} style={styles.calendarIcon} />
                      <Text style={styles.eventName}>ADRESSE</Text>
                    </View>

                    <Text style={styles.place}>
                      {event?.address?.street && event?.address?.zipCode && event?.address?.city && (
                        <>
                          {event.address.zipCode}, {event.address.city}
                        </>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.firstPartContainer}>
                <View style={styles.sectionThree}>
                  <View style={styles.titleSubtitleColumn}>
                    <Text style={styles.eventName}>
                      Billet N° {ticket.id} Prix TTC: {ticket.srcPrice} €
                    </Text>
                    <Text style={styles.place}>
                      Commande N° {ticket.id} {transformDateString(ticket.createdAt.toString())}
                    </Text>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Text style={styles.place}>
                        Client : {ticket.firstName} {ticket.lastName}
                      </Text>
                      {ticket.email && (
                        <>
                          <Image src={email} style={styles.emailIcon} />
                          <Text style={styles.place}>{ticket.email}</Text>
                        </>
                      )}
                    </View>
                  </View>
                  <View style={styles.qrColumn}>
                    <View style={styles.qrBilletCol}>
                      <Text style={styles.eventName}>
                        Billet {index + 1}/{tickets.length}
                      </Text>
                      <Image src={ticket.qrCodeUrl} style={{ width: '50%' }} />
                      <Text style={styles.eventName}>{ticket.id}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ flexDirection: 'column-reverse', width: '100%' }}>
                  <View style={styles.lastSection}>
                    <Image src={tickieLogo} style={{ width: '40px' }} />
                    <Text style={styles.place}>Powered by tickie.io</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

interface PrintPTicketButtonProps {
  tickets: Ticket[];
  event?: Event;
}

const PrintPTicketButton = ({ tickets, event }: PrintPTicketButtonProps) => {
  const handleDownload = async () => {
    const blob = await pdf(<PdfTicket tickets={tickets} event={event} />).toBlob();
    saveAs(blob, `Ticket_${event!.title}_${tickets[0].ticketReservationId}.pdf`);
  };
  return (
    <div
      onClick={handleDownload}
      className="w-full rounded-xl h-12 py-1 font-bold bg-black flex items-center justify-evenly hover:bg-slate-600 cursor-pointer text-white"
    >
      <div className="flex items-center justify-center space-x-1">
        <Icon width="9%" height="9%" />{' '}
        <span className="leading-6 text-xs sm:text-[17px]">Télécharger le e-billet</span>
      </div>
    </div>
  );
};
export default PrintPTicketButton;

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  const formattedDate = date.toLocaleString('fr-FR', options);

  return formattedDate;
};

const formatRange = (beginAt: string, endAt: string): string => {
  const beginDate = formatDate(beginAt);
  const endDate = formatDate(endAt);

  return `${beginDate} (${beginDate.slice(-5)} - ${endDate.slice(-5)})`;
};

const transformDateString = (dateString: string): string => {
  const date = new Date(dateString);

  // Extract the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};
